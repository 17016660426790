import React from "react"
import { Link } from "gatsby"
import helpCustomer from "./images/imagenContact.webp"
import TitleUnderline from "./titleUnderline"
import img from "./images/Logo.svg"

const IntroHome = ({ location }) => {
  return (
    <section className="container-intro-page-whatsapp">
      <div className="container-intro-page-whatsapp-infoTitle">
        <img src={img} alt="" />
        <div className="container-intro-page-whatsapp-infoTitle-title">
          Automatiza tu atención, marketing y ventas con la API de <TitleUnderline underline="WhatsApp Business" />
        </div>
        <div className="container-intro-page-whatsapp-infoTitle-text">
          Personaliza tus procesos de contacto y comunicación con tus clientes mediante conversaciones inteligentes, notificaciones y recorridos únicos en la aplicación más utilizada en el mundo.
          <br /><br />
          Gestiona chats desde cualquier dispositivo, distribúyelos entre agentes y crea chatbots y agentes IA dentro de WhatsApp.</div>
        <div className="container-intro-page-whatsapp-infoTitle-button">
          <Link
            className="container-intro-page-whatsapp-infoTitle-buttonOne"
            to={`/whatsapp-business-api/contacto-whatsapp-api/${location.search}`}
          >
            Accede a la API de WhatsApp
          </Link>
        </div>
        <Link
          className="container-intro-page-whatsapp-infoTitle-link"
          to={`/whatsapp-business-api/precios/${location.search}`}
        >
          Conoce los precios por consumo
        </Link>
      </div>
      <div className="container-intro-page-whatsapp-image">
        <img
          src={helpCustomer}
          alt="customer"
          loading="lazy"
        />
      </div>
    </section>
  )
}

export default IntroHome
