import React, { useEffect, useRef } from "react"
import TitleUnderline from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Agentes IA",
      description: "Crea agentes IA con información importada o integrada de tu empresa en simples pasos. Beex, Open AI y WhatsApp usarán esta información para responder a tus usuarios de manera automatizada, reduciendo las tareas repetitivas de tus agentes, logrando mejorar la experiencia de tus clientes.",
      image: imageOneMobile,
      background: "#E5FAEC"
    },
    {
      title: "Envíos de mensajes masivos",
      description: "La API de WhatsApp Business te permite enviar mensajes simultáneos y de forma masiva a toda tu base de contactos. Usa el poder del marketing conversacional, enviando ofertas, notificaciones y más. Asegura que tus clientes te lean gracias al 98% de tasa de apertura del canal.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {
      title: "Mensajes interactivos",
      description: "Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica. Añade botones y listas de mensajes cuando configures chatbots o mensajes masivos.",
      image: imageThreeMobile,
      background: "#E5FAEC"
    },
    {
      title: "Gestión entre canales",
      description: "Ya sea de teléfono o desde cualquier canal, podrás enrutar la atención de un punto de contacto a WhatsApp y viceversa. De esta forma, evitas que los usuarios tengan que repetir la información brindada al momento de cambiar de canal.",
      image: imageFourMobile,
      background: "#FCEEEE"
    },
    {
      title: "Mejor rendimiento en Ads",
      description: "Gestiona mejor los altos volúmenes de atención que provienen de las campañas de Meta Ads, Click to WhatsApp. Configura respuestas automáticas que eviten el abandono de una atención por falta de respuesta, y prospecta mejor a tus leads con las automatizaciones con IA de Beex y WhatsApp.",
      image: imageFiveMobile,
      background: "#E5FAEC"
    },
  ]

  return (
    <section className="container-experience-score">
      <p className="container-experience-score-title">
        La plataforma omnicanal de Beex ayuda a las empresas a brindar una gran <TitleUnderline underline="experiencia en"/> <TitleUnderline underline="servicio al cliente" />

      </p>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsTwo">
        {/* columna */}
        <div className="container-experience-score-cardsTwo-cards-column">
          <section className="container-experience-score-cardsTwo-card" style={{ background: "#E5FAEC" }}>
            <section>
              <p className="container-experience-score-cardsTwo-card-title">Agentes IA</p>
              <p className="container-experience-score-cardsTwo-card-description">
                Crea agentes IA con información importada o integrada de tu empresa en simples pasos. Beex, Open AI y WhatsApp usarán esta información para responder a tus usuarios de manera automatizada, reduciendo las tareas repetitivas de tus agentes, logrando mejorar la experiencia de tus clientes.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-score-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsTwo-card-title">Envíos de mensajes masivos</p>
            <p className="container-experience-score-cardsTwo-card-description">
              La API de WhatsApp Business te permite enviar mensajes simultáneos y de forma masiva a toda tu base de contactos. Usa el poder del marketing conversacional, enviando ofertas, notificaciones y más. Asegura que tus clientes te lean gracias al 98% de tasa de apertura del canal.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* columna */}
        <section className="container-experience-score-cardsTwo-card" style={{ background: "#E5FAEC", padding: "32px 10px 0px" }}>
          <section style={{ padding: "0px 22px 0px" }}>
            <p className="container-experience-score-cardsTwo-card-title">Mensajes interactivos</p>
            <p className="container-experience-score-cardsTwo-card-description">
              Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica. Añade botones y listas de mensajes cuando configures chatbots o mensajes masivos.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-score-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-score-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-score-cardsOne-card-title">Gestión entre canales</p>
            <p className="container-experience-score-cardsOne-card-description">
              Ya sea de teléfono o desde cualquier canal, podrás enrutar la atención de un punto de contacto a WhatsApp y viceversa. De esta forma, evitas que los usuarios tengan que repetir la información brindada al momento de cambiar de canal.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-score-cardsOne-card-image"
              src={imageFourDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-score-cardsOne-column">
          {/* columna */}
          <section className="container-experience-score-cardsOne-card" style={{ background: "#E5FAEC", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-score-cardsOne-card-title">Mejor rendimiento en Ads</p>
              <p className="container-experience-score-cardsOne-card-description">
                Gestiona mejor los altos volúmenes de atención que provienen de las campañas de Meta Ads, Click to WhatsApp. Configura respuestas automáticas que eviten el abandono de una atención por falta de respuesta, y prospecta mejor a tus leads con las automatizaciones con IA de Beex y WhatsApp.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-score-cardsOne-card-image"
                src={imageFiveDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>



      <div className="container-experience-score-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-score-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-score-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-score-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-score-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
